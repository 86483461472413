@import 'variables';
// panel

$tabs-nav-bg-active-color: $primary-color;
$tabs-nav-bg-active-border: 1px solid $primary-color;
$tabs-nav-border-color: #c8c8c8;

body {
  // tabs
  .ui-tabview {
    &.ui-tabview-top {
      .ui-tabview-nav {
        li {
          &.ui-state-active {
            background-color: $tabs-nav-bg-active-color;
            border: $tabs-nav-bg-active-border;
          }
        }
      }
    }

    .ui-tabview-panels {
      padding: 0;
      border: unset;
      border-top: 1px solid $tabs-nav-border-color;
    }
  }

  // removing extra padding in tab view contents since it's use now
  // on CMS settings page and bridge editor
  p-tabView {
    .ui-tabview {
      padding: 0;

      .ui-tabview-panels {
        padding: 0;

        .ui-tabview-panel {
          .container-fluid {
            width: 100%;
          }
        }
      }
    }
  }

  .ui-panel {
    box-shadow: 0 1px 5px 1px rgba(34, 47, 60, .25);
  }

  @media #{$min768} {
    p-panel.card-full-height {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ui-panel {
        flex: 1;
        display: flex;
        flex-direction: column;

        .ui-panel-content-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;

          .ui-panel-content {
            flex: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

/*******************************************************************************
*                                 Imports                                      *
*******************************************************************************/

// Bootstrap Year Calendar CSS
@import '../_local_modules/bootstrap-year-calendar/css/bootstrap-year-calendar.min';

/*******************************************************************************
*                           Global Style Overrides                             *
*******************************************************************************/

// Variables
@import 'global/variables';

// Global styles

@import 'global/structure';
@import 'global/typography';
@import 'global/helpers';
@import 'global/behaviours';
@import 'global/mixins';
@import 'global/button';
@import 'global/table';
@import 'global/inputs';
@import 'global/panel';
@import 'global/overlay';
@import 'global/menu';
@import 'global/badge';

.message-success {
  background-color: $success-color;
  border: 1px solid $success-color;
}

.message-error {
  background-color: $danger-color;
  border: 1px solid $danger-color;
}

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

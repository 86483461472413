@import 'variables';
// inputs

$input-focus-border-color: $primary-color;
$input-invalid-border: 1px solid $danger-color;
$checkbox-active-border-color: $primary-color;
$checkbox-active-bg-color: $primary-color;

body {
  // input text
  .ui-inputtext {
    width: 100%;

    &:enabled {
      &:focus {
        &:not(.ui-state-error) {
          border-color: $input-focus-border-color;
        }
      }
    }
    &.ng-dirty.ng-invalid {
      border: $input-invalid-border;
    }
  }

  .input-error {
    left: 0;
    font-size: 75%;
    color: $danger-color;
  }

  // listbox
  .ui-listbox {
    .ui-listbox-list {
      .ui-listbox-item {
        &.ui-state-highlight {
          background-color: $highlight-bg-color;
        }
      }
    }
  }

  // checkbox
  .ui-chkbox {
    .ui-chkbox-box {
      &.ui-state-active, &.ui-state-active.ui-state-focus {
        border-color: $checkbox-active-border-color;
        background-color: $checkbox-active-bg-color;
      }
    }
  }

  // switch
  .ui-inputswitch {
    &.ui-inputswitch-checked {
      .ui-inputswitch-slider {
        background-color: $primary-color;
      }
    }
  }

  .ui-autocomplete-panel {
    .ui-autocomplete-items {
      .ui-autocomplete-list-item {
        &.ui-state-highlight {
          background: $primary-color;
        }
      }
    }
  }

  // autocomplete
  .ui-autocomplete {
    &.ui-autocomplete-multiple {
      .ui-autocomplete-multiple-container {
        .ui-autocomplete-token {
          background: $primary-color;
        }
      }
    }
  }

  // chips
  .ui-chips {
    & > ul.ui-inputtext {
      .ui-chips-token {
        background: $primary-color;
      }
    }
  }
}

@import 'variables';
@import 'mixins';

// button
$button-bg-color: $primary-color;
$button-border: 1px solid $primary-color;
$button-hover-bg-color: $primary-dark-color;
$button-hover-border-color: $primary-color;

$danger-button-bg-color: $danger-color;
$danger-button-border-color: $danger-color;

body {
  .ui-button, .ui-button:enabled {
    &, &.primary-button {
      background-color: $button-bg-color;
      border-color: $button-bg-color;
      @include button-hover(ACTION)
    }
    &.accent-button {
      background-color: $accent-color;
      border-color: $accent-color;
      @include button-hover(ACCENT)
    }

    &.warn-button {
      background-color: $warn-color;
      border-color: $warn-color;
      @include button-hover(WARN)
    }
    &.ui-state-default {
      &.ui-button-secondary {
        &.ui-button-secondary--no-background {
          opacity: 1;
          transition: opacity 0.25s ease-in;

          background-color: unset;
          border-style: none;
          font-size: 16px;
          box-shadow: unset;

          &:hover {
            opacity: 0.65;
          }

          &.action-icon {
            color: $action-color;
            @include button-action-hover(p-contrast(ACTION))
          }

          &.primary-icon {
            color: $action-color;
            @include button-action-hover(p-contrast(PRIMARY))
          }

          &.accent-icon {
            color: $accent-color;
            @include button-action-hover(p-contrast(ACCENT))
          }

          &.warn-icon {
            color: $warn-color;
            @include button-action-hover(p-contrast(WARN))
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
      &.ui-button-danger {
        background-color: $danger-button-bg-color;
        border-color: $danger-button-border-color;
        &:enabled:hover {
          background-color: darken($danger-button-bg-color, 20%);
          border-color: darken($danger-button-border-color, 20%);
        }
      }
    }
  }
  .action-buttons {
    position: relative;
    float: right;
    font-size: 1.35rem;

    .fas {
      &.redirect {
        color: $action-color;
      }
    }
  }
}

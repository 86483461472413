@import 'variables';

html {
  font-size: $main-fs;
  line-height: 22px;
}

h1, .h1,
h2, .h2 {
    font-family: $headers-ff-main;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $headers-ff-second;
  font-weight: $headers-fw;
  margin-top: $module-rem * 2;
  margin-bottom: $module-rem * 2;
}
h1, .h1 {
  font-size: xx-large;
  font-weight: 700;
  line-height: 1.13;
  margin-bottom: 3.1428572rem;
}
h2, .h2 {
  font-weight: 300;
  font-size: x-large;
  line-height: 1;
}
h3, .h3 {
  font-size: 2.3571429rem;
  line-height: 1.34;
}
h4, .h4 {
  font-size: 1.7857143rem;
  line-height: 1.32;
}
h5, .h5 {
  font-size: 1.3571429rem;
  line-height: 1.21;
}
h6, .h6 {
  font-size: 1rem;
  line-height: 1.6;
}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;
  margin-bottom: $module-rem * 2;

  &:last-child {
    margin-bottom: 0;
  }
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
hr, .hr {
  border: 1px solid;
  margin: -1px 0;
}
sub, sup {
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a {
  color: rgba(main-color(500),.8);
  text-decoration: none;
  transition: color .2s $animation;

  &:hover,
  &:focus {
    color: main-color(500);
    outline: none;
    text-decoration: none;
  }

  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):focus,
  &:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
  }
}

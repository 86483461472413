img {
  height: auto;
  max-width: 100%;
}
button {
  &:focus {
    outline: none;
  }
}
.form-group {
  align-items: center;
}
.form-message {
  margin-top: .1rem;
  position: absolute;
  top: 100%;
}

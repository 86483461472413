.mt-module {
  margin-top: $module-rem * 2 !important;
}

.mr-module {
  margin-right: $module-rem * 2 !important;
}

.mb-module {
  margin-bottom: $module-rem * 2 !important;
}

.ml-module {
  margin-left: $module-rem * 2 !important;
}

.text-danger {
  color: $danger-color !important;
}

.circle {
  border-radius: 50%;
}


//
// Text
//

// Responsive alignment

.text-align-left {
  text-align: left;
}

.font-weight-light {
  font-weight: 300!important;
}

// Sizing

// Helpers for sizing and for not use boostrap's helpers
.j-w-100 {
  width: 100%;
}

.j-h-100 {
  height: 100%;
}

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge((
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
), $spacers);
$sides: (top, bottom, left, right);

body {
  @each $size, $length in $spacers {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$size} {
        margin-#{$side}: #{$length} !important;
      }

      .p#{str-slice($side, 0, 1)}-#{$size} {
        padding-#{$side}: #{$length} !important;
      }
    }
  }
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 1.5rem;
}

.float-right {
  float: right;
}

.d-none {
  display: none!important;
}

.d-inline-block {
  display: inline-block;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.text-left {
  text-align: left;
}

.calendar .month-container {
  &.col-3 {
    @extend .p-col-3
  }
  &.col-12 {
    @extend .p-col-12
  }
}

[class*="p-xs-"], [class*="p-sm-"], [class*="p-md"], [class*="p-lg"] {
  position: relative;
}

@import 'variables';
// menu

body {
  .ui-dropdown-panel {
    .ui-dropdown-items {
      .ui-dropdown-item.ui-state-highlight, .ui-dropdown-item-group.ui-state-highlight {
        background-color: $primary-color;
      }
    }
  }
}


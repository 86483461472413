@import 'variables';
// table

body {
  .ui-table {
    .ui-sortable-column {
      &.ui-state-highlight {
        background-color: $highlight-bg-color;
      }
    }
  }

  .ui-paginator {
    .ui-paginator-pages {
      .ui-paginator-page {
        &.ui-state-active {
          background-color: $primary-color;
        }
      }
    }
  }

  p-table {
    .action-buttons {
      p-button, button {
        margin-left: 4px;
      }
    }
  }

  p-tree {
    .ui-tree {
      width: 100%;
    }
  }

  // bootstrap todo to remove
  .table-inverse,
  .thead-inverse th {
    color: main-contrast(500);
    background-color: main-color(500);
  }
}

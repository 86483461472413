@import 'variables';
@import 'node_modules/primeflex/primeflex.scss';

@mixin full-size-relative-container {
  display: block;
  position: relative;
  height: 100%;
}

@mixin button-hover($target-key) {
  &:hover {
    border-color: p-contrast($target-key);
    background-color: p-contrast($target-key);
    color: #fff;

    &:disabled {
      cursor: unset;
      background-color: p-color($target-key);
    }

    &:active {
      border-color: p-contrast($target-key);
      background-color: p-contrast($target-key);
    }
  }

  &:focus {
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }
}

@mixin button-action-hover($target-color) {
  &:hover {
    border-color: unset;
    background-color: unset;
    color: $target-color;
  }

  &:focus {
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
}

@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

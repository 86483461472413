@import 'variables';

body {
  .ui-tooltip {
    &.custom-position {
      max-width: 80%;
      text-align: center;
      left: 20% !important;
      right: 20% !important;
    }
    @media #{$max543} {
      // All primeng dialog will take full width when meeting a small screen
      .ui-dialog {
        width: 100% !important;
      }
    }
  }

  .badge {
    &.badge-bright {
      background-color: $action-color;
      color: accent-color(A100);
      font-family: $second-ff;
    }
  }

  .ui-dialog {
    @media #{$max543} {
      width: 100%;
      max-width: 100%;
    }
  }
}

@import 'variables';
@import 'node_modules/primeflex/primeflex.scss';

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: $main-ff;
  font-size: 1rem;
  font-weight: $main-fw;
  line-height: $main-lh;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  margin: 0;
  background: $mainBg;
  overflow-x: hidden;

  .layout-wrapper {
    min-height: 100%;

    .layout-main {
      padding-top: $navbar-height + $breadcrumb-height;
      padding-bottom: $footer-height;
      min-height: 100vh;
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      width: 100%;
      position: relative;
      margin-right: auto;
      margin-left: auto;

      .layout-content {
        position: relative;
        width: 100%;
        padding: 16px;

        > * {
          > .p-grid {
            flex-grow: 1;
          }
        }

        > router-outlet {
          display: none;
        }
      }

      @media #{$max543} {
        padding-top: $navbar-height;
      }
    }
  }

  .row {
    @extend .p-grid;
  }

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

app {
  display: block;
  height: 100%;
}
